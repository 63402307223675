import React, {useRef, createRef} from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import styles from "./hyperlink-gallery.module.css"

class HyperlinkGallery extends React.Component{
  constructor(props) {
    super(props);
    // console.log(props.data)
    this.artists = props.data.googleSheet.fields.hyperlinkArtists;
    this.interval = null;
    this.sinnerInterval = null;

    this.header = createRef();
    this.artistsRef = createRef();
    this.spinner = createRef();
    // this.state = { showMenu: true };
    this.sayHello = this.sayHello.bind(this)
    this.changeDom = this.changeDom.bind(this)
    this.spinSpinner = this.spinSpinner.bind(this)

    this.reset = this.reset.bind(this)
    this.resetting = false;

    this.degreeTracker = []
    this.artistsYTracker = [];
    this.spinnerAngle = 0;
    this.spinnerCounter = 0;
    // this.move=true;
  }
  // console.log(data.googleSheet.fields.hyperlinkArtists);
  changeDom(){
    // console.log("hi")
    // if(!this.move) return
    // console.log("hi", this.header.current.style);
    for(let i = 0 ; i< this.header.current.children.length; i++){
      if(Math.random()<0.005 && !this.resetting){
        //console.log(this.header.current.children[i]);
        let randomInc = -45 + Math.random()*90;
        let newDegree = this.degreeTracker[i]-randomInc
        this.header.current.children[i].style = 'transform: rotate('+newDegree+'deg);'
        this.degreeTracker[i] = newDegree
      }else if(this.resetting){
        this.header.current.children[i].style = 'transform: rotate(0deg);'

      }
    }
    for(let i = 0 ; i< this.artistsRef.current.children.length; i++){
      //console.log(this.artistsRef.current.children[i].children);
      for(let j = 0 ; j< this.artistsRef.current.children[i].children.length; j++){
        if(Math.random()<0.00025 && !this.resetting){
          // // only Y
          // console.log(this.artistsRef.current.children[i].innerText)
          // console.log(this.artistsRef.current.children[i].children[j].innerHTML)
          console.log("WIDTH", this.artistsRef.current.children[i].offsetWidth)
          // console.log("POS PARENT", this.artistsRef.current.children[i].offsetLeft)
          // console.log("POS LETTER", this.artistsRef.current.children[i].children[j].offsetLeft)
          // console.log("DIFFERENCE", this.artistsRef.current.children[i].children[j].offsetLeft - this.artistsRef.current.children[i].offsetLeft)
          //
          // console.log("--")

          let wordWidth = this.artistsRef.current.children[i].offsetWidth;
          // let wordMid = this.artistsRef.current.children[i].offsetLeft + (this.artistsRef.current.children[i].offsetWidth/2)
          // let maxX =

          // X
          let randomInc = -100 + Math.random()*200;
          let newX = this.artistsYTracker[i][j][1]-randomInc;
          if(Math.abs(newX) <= wordWidth*2){
            this.artistsRef.current.children[i].children[j].style = 'transform: translateX('+newX+'px);'
            this.artistsYTracker[i][j][0] = newX;
          }

          // Y
          // let randomInc = -10 + Math.random()*20;
          // let newY = this.artistsYTracker[i][j][1]-randomInc;
          // this.artistsRef.current.children[i].children[j].style = 'transform: translateY('+newY+'px);'
          // this.artistsYTracker[i][j][1] = newY;

          // // X and Y
          // if(Math.random()<0.5){
          //   let randomInc = -60 + Math.random()*120;
          //   let newY = this.artistsYTracker[i][j][1]-randomInc;
          //   let currentX = this.artistsYTracker[i][j][0]
          //   this.artistsRef.current.children[i].children[j].style = 'transform: translate('+currentX+'px,'+newY+'px);'
          //   this.artistsYTracker[i][j][1] = newY;
          // }else{
          //   let randomInc = -60 + Math.random()*120;
          //   let currentY = this.artistsYTracker[i][j][1]
          //   let newX = this.artistsYTracker[i][j][0]-randomInc;
          //   this.artistsRef.current.children[i].children[j].style = 'transform: translate('+newX+'px,'+currentY+'px);'
          //   this.artistsYTracker[i][j][0] = newY;
          // }

        }else if(this.resetting){
          this.artistsRef.current.children[i].children[j].style = 'transform: translateY(0px);'

        }
      }


      // if(Math.random()<0.1){
      //   console.log(this.header.current.children[i]);
      //   let randomInc = -360 + Math.random()*720;
      //   let newDegree = this.degreeTracker[i]-randomInc
      //   this.header.current.children[i].style = 'transform: rotate('+newDegree+'deg);'
      //   this.degreeTracker[i] = newDegree
      // }
    }
  }
  spinSpinner(){
    if(!this.resetting){
      this.spinnerAngle -= 50;
    }
    this.spinner.current.style = 'transform: rotate('+this.spinnerAngle+'deg);'
  }

  componentDidMount() {

    // setTimeout(()=>{
    //   this.interval = setInterval(this.changeDom, 10);
    // }, 5000)

     this.interval = setInterval(this.changeDom, 500);
    //  this.spinnerInterval = setInterval(this.spinSpinner, 2000);  <---------

     let letters = this.header.current.innerHTML.split("");
     for(let letter of letters){
       this.degreeTracker.push(0);
     }
     let newHTML = letters.map(letter=>letter==" "?" <span class="+styles.headlineBreaker+"></span>":"<span>"+letter+"</span>").join("");
     //console.log(newHTML)
     this.header.current.innerHTML = newHTML;
     //console.log(this.artistsRef);
     // store intervalId in the state so it can be accessed later:
     // this.setState({intervalId: intervalId});
  }
  componentWillUnmount() {
     // use intervalId from the state to clear the interval

     clearInterval(this.interval);
    //  clearInterval(this.spinnerInterval);  <---------

  }

  sayHello(){
    // console.log("HIeloo");

    // console.log("hi", this.header.current.children);
    //
    // for(let i = 0 ; i< this.header.current.children.length; i++){
    //   if(Math.random()<0.1){
    //     console.log(this.header.current.children[i]);
    //     let randomInc = -360 + Math.random()*720;
    //     let newDegree = this.degreeTracker[i]-randomInc
    //     this.header.current.children[i].style = 'transform: rotate('+newDegree+'deg);'
    //     this.degreeTracker[i] = newDegree
    //   }
    // }
    // console.log(this.degreeTracker);

    //console.log("hi", this.artistsRef.current.children);

    // for(let i = 0 ; i< this.artistsRef.current.children.length; i++){
    //   //console.log(this.artistsRef.current.children[i].children);
    //   for(let j = 0 ; j< this.artistsRef.current.children[i].children.length; j++){
    //     if(Math.random()<0.02){
    //       let randomInc = -60 + Math.random()*120;
    //       let newDegree = this.artistsYTracker[i][j]-randomInc;
    //       this.artistsRef.current.children[i].children[j].style = 'transform: translateY('+newDegree+'px);'
    //       this.artistsYTracker[i][j] = newDegree
    //     }
    //   }
    //   // if(Math.random()<0.1){
    //   //   console.log(this.header.current.children[i]);
    //   //   let randomInc = -360 + Math.random()*720;
    //   //   let newDegree = this.degreeTracker[i]-randomInc
    //   //   this.header.current.children[i].style = 'transform: rotate('+newDegree+'deg);'
    //   //   this.degreeTracker[i] = newDegree
    //   // }
    // }
    // console.log(this.degreeTracker);


    // this.header.current.style= 'transform: rotate(100deg);'
    // console.log(this.refs)
  }
  shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
  }
  getArtists(){
    this.artists = this.shuffle(this.artists)
    return this.artists.map((artist, i)=>{
      //console.log(artist, i);
      this.artistsYTracker.push([]);
      let marginLeft = 50+Math.random()*200;
      let marginRight = 50+Math.random()*200;
      let letters = artist.text.split("");
      for(let letter of letters){
        this.artistsYTracker[i].push([0,0])
      }
      let newHTML = letters.map(letter=>letter==" "?" ":"<span>"+letter+"</span>").join("");
      return <a key={i} href={artist.link} style={{marginLeft:marginLeft+"px", marginRight:marginRight+"px"}} dangerouslySetInnerHTML={{__html: newHTML}}></a>
    })
  }
  reset(){
    for(let i = 0 ; i< this.artistsRef.current.children.length; i++){
      for(let j = 0 ; j< this.artistsRef.current.children[i].children.length; j++){

          // this.artistsRef.current.children[i].children[j].style = 'transform: translate(0px,0px);';
          this.artistsYTracker[i][j] = [0,0];

      }
      for(let i = 0 ; i< this.header.current.children.length; i++){
        // this.header.current.children[i].style = 'transform: rotate('+0+'deg);';
        this.degreeTracker[i] = 0;
      }
    }
    this.spinnerAngle += 1800;
    this.resetting = true;
    this.spinSpinner();
    this.changeDom();

    setTimeout(()=>{
      this.resetting = false;
      // this.spinSpinner();
    }, 2000)
    // this.move = false;
  }

  render(){
    //console.log(this.header);
    return(
      <Layout>
        <div className={styles.headlineWrapper}>
        {/*}<button onClick={this.sayHello}>H</button>*/}
          <h1 className={styles.headline} ref={this.header}>Hyperlink Gallery</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.announce}>
            <p className={styles.reset}><a onClick={this.reset} ref={this.spinner}><span>꩜</span></a></p>
            <p>Currently on display:</p>

          </div>
          <div className={styles.artistContainer} ref={this.artistsRef}>
            {this.getArtists()}
          </div>
        </div>
      </Layout>
    )
  }
}


export default HyperlinkGallery

export const query = graphql`
query {
  googleSheet {
    fields {
      hyperlinkArtists {
        link
        text
      }
    }
  }
}
`
